import {Component, Input, OnInit} from '@angular/core';
import {FaultData} from '../../../models/graph-data';
import {Ticket} from '../../../models/ticket';
import {FaultService, PredefinedView} from '../../../services/fault.service';
import {GraphControlService} from '../../../services/graph-control.service';

@Component({
  selector: 'app-predefined-views',
  templateUrl: './predefined-views.component.html',
  styleUrls: ['./predefined-views.component.scss']
})
export class PredefinedViewsComponent implements OnInit {

  @Input('signalData')
  set setSignalData(data: FaultData) {
    if (data) {
      this.setViews(data).then();
    }
  }

  @Input()
  ticket: Ticket;

  availableViews: Array<PredefinedView>;

  private emptyView: PredefinedView = {viewName: "", signals: []};

  constructor(private readonly faultService: FaultService, private readonly graphControlService: GraphControlService) { }

  ngOnInit() {
  }

  private async setViews(data: FaultData) {
    console.log(data);
    const allViews = await this.faultService.getDefaultViews();
    const availableViews = [];
    availableViews.push(this.emptyView);
    const allSignalCodes = data.signals.map(s => s.signalCode);
    for (const view of allViews) {
      const newView: PredefinedView = {viewName: view.viewName, signals: []};
      for (const signal of view.signals) {
        const newSignal = {channel: signal.channel, signals: []};
        for (const signalCode of signal.signals) {
          if (typeof(signalCode) === 'object') {
            const signalName = Object.keys(signalCode)[0];
            const signal1 = signalCode[signalName]['signal1'];
            const signal2 = signalCode[signalName]['signal2'];
            if (allSignalCodes.includes(signal1) && allSignalCodes.includes(signal2)) {
              newSignal.signals.push(signalCode);
            }
          } else if (allSignalCodes.includes(signalCode)) {
            newSignal.signals.push(signalCode);
          }
        }
        if (newSignal.signals.length) {
          newView.signals.push(newSignal);
        }
      }
      if (newView.signals.length) {
        availableViews.push(newView);
      }
    }
    this.availableViews = availableViews;
  }

  selected(event: Event) {
    const selected = this.availableViews.find(v => v.viewName === event.target['value']);
    if (selected && selected.viewName) {
      this.graphControlService.announcePredefinedViewSelected(selected);
    }
  }
}
