const env = 'uat';
const baseUrl = "https://wwn6q710ia.execute-api.us-east-1.amazonaws.com/" + env;
const amplifyConfig = require('../assets/amplify.' + env + '.json');

export const environment = {
  production: false,
  userApiUrl: baseUrl + "/user",
  faultApiUrl: baseUrl + "/fault-ticket/",
  ruleEngineUrl: baseUrl + "/rule-engine/generate",
  dashboardUrl: baseUrl + "/dashboard/",
  emailUrl: baseUrl + "/emails/send-email",
  amplify: amplifyConfig
};
