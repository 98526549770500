import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  currentUserId: string | undefined;
  isTechnician: boolean;

  constructor(private readonly authService: AuthService, private router: Router) {
    this.authService.auth$.subscribe(authState => {
      this.currentUserId = authState.userId;
      this.isTechnician = authState.isTechnician;
    });
  }

  ngOnInit() {
  }

  async signOutClick() {
    this.currentUserId = undefined;
    this.isTechnician = false;
    await this.authService.signOut();
    await this.router.navigate(['/login'], {state: {data: "Successfully Signed Out"}});
  }
}
